.esquema {
	width:100%;
	text-align:-webkit-right;

	padding-top: 10px; 
}

.form-style-1 {
	margin:10px 30px;

}
.form-style-1 li {
	padding: 0;
	display: block;
	list-style: none;
	margin: 10px 0 0 0;
}
.form-style-1 label{
	margin:0 0 3px 0;
	padding:0px;
	display:block;
}
.form-style-1 input,
textarea, 
select{

	font-family: poppins;
	font-size: 12px;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	border:1px solid #BEBEBE;
	border-radius: 4px;
	padding: 7px;
	margin:0px;	
	outline: none;	
	width: 100%;
}
.form-style-1 input:focus{	
	border: 2px solid #2684FF;
}

.form-style-1 input:hover{
	border: 1px solid #A5A5A5;
}

.form-style-1 input:hover:focus{
	border: 2px solid #2684FF;
}


.form-style-1 .field-divided{
	width: 49%;
}

.form-style-1 .field-long{
	width: 100%;
}
.form-style-1 .field-select{
	width: 100%;
}
.form-style-1 .field-textarea{
	height: 100px;
}
.form-style-1 input[type=submit], .form-style-1 input[type=button]{
	background: #4B99AD;
	padding: 8px 15px 8px 15px;
	border: none;
	color: #fff;
}
.form-style-1 input[type=submit]:hover, .form-style-1 input[type=button]:hover{
	background: #4691A4;
	box-shadow:none;
	-moz-box-shadow:none;
	-webkit-box-shadow:none;
}
.form-style-1 .required{
	color:red;
}

/* CSS */
.button-7 {
  background-color: #091d44;
  border: 1px solid transparent;
  border-radius: 3px;
  box-shadow: rgba(255, 255, 255, .4) 0 1px 0 0 inset;
  box-sizing: border-box;
  color: #fff;
  margin-left: 30px;
  padding-left: 10px;
  padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  font-family: Poppins;
  font-size: 12px;
  float: left;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  transition: 0.2s;
}

.button-7:hover,
.button-7:focus {
  background-color: #ea6c0b;
}

.button-7:focus {
  box-shadow: 0 0 0 4px rgba(0, 149, 255, .15);
}

.button-7:active {
  background-color: #0064bd;
  box-shadow: none;
}


/* CSS */
.button-8 {
	background-color: #F13131;
	border: 1px solid transparent;
	border-radius: 3px;
	box-shadow: rgba(255, 255, 255, .4) 0 1px 0 0 inset;
	box-sizing: border-box;
	color: #fff;
	margin-left: 30px;
	padding-left: 10px;
	padding-top: 10px;
	padding-right: 10px;
	padding-bottom: 10px;
	font-family: Poppins;
	font-size: 12px;
	float: left;
	text-decoration: none;
	user-select: none;
	-webkit-user-select: none;
	transition: 0.2s;
  }
  
  .button-8:hover,
  .button-8:focus {
	background-color: #ea6c0b;
  }
  
  .button-8:focus {
	box-shadow: 0 0 0 4px rgba(0, 149, 255, .15);
  }
  
  .button-8:active {
	background-color: #0064bd;
	box-shadow: none;
  }

  .wrapper {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 10px;
  }

  .five {
	text-Align: initial; 
	font-Family: Poppins;
	padding-Left: 30px;
	padding-Bottom: 14px;
	font-Size: 14px;
  }
  
  .buttonFa {
	width: 10px;
	height: 10px;
	font-Size: 14px;
	display:initial;
  }
  
  .buttonSpan {
	font-Family:Poppins;
	font-Size:12px;
	display: initial;
  }

  
.listado>ul {
	margin:7px 0;
	}

.listado li {
position:relative;
display:block;

}

.listado li>a {
position:relative;
display:table;
border-collapse:collapse;
border-spacing:0;
color:#000;
	font-family: Poppins;
font-size: 14px;
text-decoration:none;
-webkit-transform:translateZ(0) scale(1,1);
-webkit-transition:all .1s linear;
transition:all .1s linear;
	
}

.listado .nav-icon {
position:relative;
display:table-cell;

text-align:center;
vertical-align:middle;
font-size:18px;
}

.listado .nav-text {
position:relative;
display:table-cell;
vertical-align:middle;
width:190px;
	font-family: 'Poppins';
	font-size: 12px;
}


.pruebita>ul.logout {
position:absolute;
left:0;
bottom:0;
}


.modal {
	display: none;
	position: fixed;
	z-index: 8;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	background-color: rgb(0, 0, 0);
	background-color: rgba(0, 0, 0, 0.4);
  }
  .modal-content {
	margin: 50px auto;
	border: 1px solid #999;
	width: 60%;
  }

  .close {
	color: #fff;
	float: right;
	font-size: 28px;
	font-weight: bold;
	-webkit-user-select: none;
	transition: 0.2s;
  }
  .close:hover,
  .close:focus {
	color: black;
	text-decoration: none;
	cursor: pointer;
  }


.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}
.exform {
	padding: 25px;
	margin: 25px;
	box-shadow: 0 2px 5px #f5f5f5;
	background: #eee;
  }

  .file-upload-wrapper {
	position: relative;
	width: 100%;
	height: 40px;
  }
  .file-upload-wrapper:after {
	content: attr(data-text);
	font-size: 18px;
	position: absolute;
	top: 0;
	left: 0;
	background: #fff;
	padding: 10px 15px;
	display: block;
	width: calc(100% - 40px);
	pointer-events: none;
	z-index: 20;
	height: 40px;
	line-height: 22px;
	color: #999;
	border-radius: 5px 10px 10px 5px;
	font-weight: 300;
  }
  .file-upload-wrapper:before {
	content: "Seleccionar Archivo";
	position: absolute;
	top: 0;
	right: 0;
	display: inline-block;
	height: 40px;
	background-color: #091d44;
	-webkit-user-select: none;
	transition: 0.2s;
	color: #fff;
	z-index: 25;
	font-size: 12px;
	font-family: poppins;
	line-height: 40px;
	padding: 0 15px;
	pointer-events: none;
	border-radius: 0 5px 5px 0;
  }
  .file-upload-wrapper:hover:before {
	background-color: #ea6c0b;
  }
  .file-upload-wrapper input {
	opacity: 0;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 99;
	height: 40px;
	margin: 0;
	padding: 0;
	display: block;
	cursor: pointer;
	width: 100%;
  }

  .wrappermodal {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 10px;

  }
  .one {
	grid-column: 1 / 3;
	grid-row: 1 ;
  }
  .two {
	grid-column: 3;
	grid-row: 1;
  }

  .input-group-search {
	margin-bottom: 26px;
  }
  .input-group-search:nth-last-child(-n + 2) {
	margin-bottom: 50px;
  }
  .input-group-search > label,
  .input-group-search > input {
	font-family: 'Poppins';
	max-width: 420px;
	text-align: left;
	width: 80%;
  }
  .input-group-search > label {
	color: #000;
	display: inline-block;
	margin-bottom: 5px;
	text-transform: uppercase;
  }
  .input-group-search > input {
	background: #eee;
	border-radius: 4px;
	border: 2px solid #999;
	color: #000;
	font-size: 16px;
	height: 40px;
	outline: 0;
	padding: 0 15px;
	transition: all 0.3s;
  }
  .input-group-search > input:focus {
	background: #fff;
	border-color: #000;
  }
  .input-group-search > input::placeholder {
	color: #999;
	font-size: 16px;
  }
  
  .confirm {
	background: #999;
	color: white;
	width: 50px;
	height: 50px;
	border-radius: 30px;
	-webkit-user-select: none;
	transition: 0.2s;
	border:none;
	margin-left: 4px;
  }

  .confirm:hover {
	background: #0b7dda;
  }

  .confirm .fa {
	position:unset;
	display:unset;
	width: 60px;
	height: 36px;
	text-align: center;
	vertical-align: middle;
	font-size:20px;
	}

	
.modalTwo {
	display: none;
	position: fixed;
	right: 0;
	z-index: 8;
	top: 0;
	width: 83%;
	height: 100%;
	overflow: auto;
	background-color: rgb(0, 0, 0);
	background-color: rgba(0, 0, 0, 0.4);
  }
  .modalTwo-content {
	margin: 10px auto;
	width: 95%;
  }

  @-webkit-keyframes orbit {
	0% {
	  -webkit-transform: rotateY(90deg) rotate(0deg);
	}
	60% {
	  -webkit-transform: rotateY(90deg) rotate(-180deg);
	}
	100% {
	  -webkit-transform: rotateY(90deg) rotate(-360deg);
	}
  }
  @-moz-keyframes orbit {
	0% {
	  -moz-transform: rotateY(90deg) rotate(0deg);
	}
	60% {
	  -moz-transform: rotateY(90deg) rotate(-180deg);
	}
	100% {
	  -moz-transform: rotateY(90deg) rotate(-360deg);
	}
  }
  @keyframes orbit {
	0% {
	  -webkit-transform: rotateY(90deg) rotate(0deg);
	  -moz-transform: rotateY(90deg) rotate(0deg);
	  -ms-transform: rotateY(90deg) rotate(0deg);
	  -o-transform: rotateY(90deg) rotate(0deg);
	  transform: rotateY(90deg) rotate(0deg);
	}
	60% {
	  -webkit-transform: rotateY(90deg) rotate(-180deg);
	  -moz-transform: rotateY(90deg) rotate(-180deg);
	  -ms-transform: rotateY(90deg) rotate(-180deg);
	  -o-transform: rotateY(90deg) rotate(-180deg);
	  transform: rotateY(90deg) rotate(-180deg);
	}
	100% {
	  -webkit-transform: rotateY(90deg) rotate(-360deg);
	  -moz-transform: rotateY(90deg) rotate(-360deg);
	  -ms-transform: rotateY(90deg) rotate(-360deg);
	  -o-transform: rotateY(90deg) rotate(-360deg);
	  transform: rotateY(90deg) rotate(-360deg);
	}
  }

  .buttonDownload {
	display: inline-block;
	border-radius: 10px;
	border: 1px solid rgba(0, 0, 0, 0.05);
	position: relative;
	padding: 20px 30px 20px 70px;
	background: rgba(34, 99, 99);
	color: rgba(0, 0, 0);
	cursor: pointer;
	-webkit-transform-style: preserve-3d;
	-moz-transform-style: preserve-3d;
	-ms-transform-style: preserve-3d;
	-o-transform-style: preserve-3d;
	transform-style: preserve-3d;
	-webkit-transition: all 500ms ease-in-out;
	-moz-transition: all 500ms ease-in-out;
	transition: all 500ms ease-in-out;
  }
  .buttonDownload:hover {
	-webkit-transform: translateY(-5px);
	-moz-transform: translateY(-5px);
	-ms-transform: translateY(-5px);
	-o-transform: translateY(-5px);
	transform: translateY(-5px);
	color: rgba(0, 0, 0);
	background: rgba(48, 73, 73);
  }
  .buttonDownload:hover > span {
	-webkit-transition: all 800ms ease-in-out;
	-moz-transition: all 800ms ease-in-out;
	transition: all 800ms ease-in-out;
	-webkit-transform: rotateY(90deg) rotate(-360deg);
	-moz-transform: rotateY(90deg) rotate(-360deg);
	-ms-transform: rotateY(90deg) rotate(-360deg);
	-o-transform: rotateY(90deg) rotate(-360deg);
	transform: rotateY(90deg) rotate(-360deg);
  }
  .buttonDownload > span {
	width: 80px;
	display: block;
	position: absolute;
	left: 0;
	top: -40px;
	bottom: -40px;
	border-radius: 50%;
	-webkit-transform-style: preserve-3d;
	-moz-transform-style: preserve-3d;
	-ms-transform-style: preserve-3d;
	-o-transform-style: preserve-3d;
	transform-style: preserve-3d;
	-webkit-transform: rotateY(90deg) rotate(0deg);
	-moz-transform: rotateY(90deg) rotate(0deg);
	-ms-transform: rotateY(90deg) rotate(0deg);
	-o-transform: rotateY(90deg) rotate(0deg);
	transform: rotateY(90deg) rotate(0deg);
  }
  .buttonDownload > span > span {
	position: absolute;
	background: rgba(0, 0, 0, 0.6);
	width: 16px;
	height: 17px;
	left: 0;
	top: 50px;
	-webkit-transform: rotateY(-90deg);
	-moz-transform: rotateY(-90deg);
	-ms-transform: rotateY(-90deg);
	-o-transform: rotateY(-90deg);
	transform: rotateY(-90deg);
  }
  .buttonDownload > span > span::before {
	position: absolute;
	content: "";
	top: 100%;
	left: 0;
	margin-left: -7px;
	border-top: 16px solid rgba(0, 0, 0, 0.6);
	border-bottom: 0 solid transparent;
	border-left: 15px solid transparent;
	border-right: 15px solid transparent;
  }
